import React, { ReactElement } from "react"
import Button, { ButtonLink } from "../components/Button/Button"
import LawliteFeature from "../components/HomePage/LawliteFeature"
import Seo from "../components/seo"
import DefaultLayout from "../templates/DefaultLayout"
import { ContactForm } from "./contact"

interface Props {}

export default function BookADemo({}: Props): ReactElement {
  return (
    <DefaultLayout>
      <Seo title="Book a Demo" />
      <div className="min-h-[80vh] flex items-center justify-center flex-col text-br-primary-blue text-center px-4">
        <h1 className="text-3xl md:text-5xl  pb-4">Book a Demo</h1>

        <p className="text-lg md:text-2xl mt-5 max-w-[600px] pb-6">
          Choose a date and time that works for you.
        </p>
        <ButtonLink
          to="https://calendly.com/lawlite/lawlite-demo-zoom"
          external
          className="text-2xl my-5 lg:my-10 lg:py-[20px] lg:px-6"
        >
          Choose a Date &amp; Time
        </ButtonLink>
        <p className="text-base md:text-2xl mt-8 text-center ">
          Don’t see a date &amp; time that works for you?
          <br />
          Contact us with the form below.
        </p>
        <p className="text-base md:text-2xl mt-8 lg:mt-10  mb-10 max-w-[750px]  text-center ">
          Our demos are both presentation and conversation. They are a chance
          for us to listen to you, answer any questions, and demonstrate <br />{" "}
          how we can add significant value to your practice.
        </p>
      </div>
      <ContactForm overlay/>
      <LawliteFeature />
    </DefaultLayout>
  )
}
